<template>
  <div class="left-bar_div">
    <a href="javascript:;" class="menu_div" v-for="(item, index) in availableMenus" :key="index" :class="{'menu-item_div_active': item.url === currentRouterName || inThisMenu(item)}" @click="clickMenu(item)">
      <div class="menu-item-name">{{item.name}}</div>
      <div class="menu-item-children" v-if="item.children && item.children.length > 0">
        <div class="menu-item-children-item" v-for="(itemB, indexB) in item.children" :key="indexB" :class="{'menu-item-children-item-last': indexB === (item.children.length - 1)}">
          <a href="javascript:;" class="menu-2-name" @click="clickMenu(itemB)">{{itemB.name}}</a>
          <div class="menu-3-div" v-if="itemB && itemB.children && itemB.children.length > 0">
            <a class="menu-3-name" href="javascript:;" v-for="(itemC, indexC) in itemB.children" :key="indexC" @click="clickMenu(itemC)">{{itemC.name}}</a>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'TheMenu',
  data () {
    return {
      maxWidth: 10000
    }
  },
  mounted () {
    this.maxWidth = this.$el.clientWidth
  },
  methods: {
    clickMenu (item) {
      if (item.type === 'sys_page') {
        if (isNaN(item.url)) {
          this.$router.push({name: item.url})
        } else {
          this.$router.push({path: '/page/' + item.url})
        }
      } else if (item.type === 'out_page') {
        this.$store.dispatch('setOutPageUrl', item.url)
        this.$router.push({name: 'business.out_page'})
      } else if (item.type === 'menu' && item.url) {
        this.$router.push({name: item.url})
      }
    },
    inThisMenu (item) {
      let inMenu = false
      if (item.url === this.currentRouterName) {
        inMenu = true
      } else {
        if (item.children && item.children.length > 0) {
          item.children.forEach((item) => {
            let inMenuC = this.inThisMenu(item)
            if (inMenuC) {
              inMenu = true
            }
          })
        }
      }
      return inMenu
    }
  },
  computed: {
    // childrenMenuStyle: {
    //   get () {
    //     let data = {
    //       width: '100%'
    //       // width: (this.availableMenus.length > 2 ? this.availableMenus.length : 3) * 8 + 'rem',
    //       // 'max-width': this.maxWidth + 'px'
    //     }
    //     return data
    //   }
    // },
    currentRouterName: {
      get () {
        return this.$store.state.route.name
      }
    },
    availableMenus: {
      get () {
        return this.$store.getters.currentMenus
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/values.less';
@import '../../styles/theme.less';
.menu-2-name {
  font-weight: 400;
  line-height: 1;
  padding: 0.5rem 0 1rem;
  white-space: nowrap;
  overflow: hidden;
  color: #dc0023;
  text-overflow: ellipsis;
  width: 8rem;
  min-width: 8rem;
  font-size: 14px;
}
.menu-3-name {
  font-weight: 300;
  line-height: 1;
  padding: 0.5rem 0 1rem;
  min-width: 185px;
  max-width: 185px;
  overflow: hidden;
  color: #000 !important;
}
.menu-item-children {
  position: absolute;
  display: none;
  flex-wrap: wrap;
  z-index: 100;
  top: @the-heading-height;
  right: 0;
  left: 0;
  background: #fff;
  box-shadow: @size-shadow @color-shadow;
  padding-bottom: 1rem;
}
.menu_div {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: @time-transition;
  border-bottom: @size-border-l solid rgba(0,0,0,0);
  padding-top: @size-border-l;
  font-weight: 300;
  max-width: 8rem;
}
.menu_div:hover {
  .menu-item-children {
    z-index: 2;
    display: flex;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
   }
 }
.menu-3-div {
  display: flex;
  flex-direction: column;
}
.menu-item-name {
  letter-spacing: @size-letter-spacing;
  color: #fff;
  font-weight: 500;
}
.menu-item-children-item {
  min-width: 8rem;
  margin: 0.75rem 0 0.75rem 2.5rem;
  min-width: calc(25% - 2.5rem);
  border-bottom: @size-border solid transparent;
  &:last-child {
    border-bottom-width: 0;
  }
}
.left-bar_div {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  font-size: @size-font-n;
}
</style>
